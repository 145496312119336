import '@hotwired/turbo-rails'
import * as ActiveStorage from '@rails/activestorage'
import '@rails/actiontext'
import 'trix'

import './channels'
import './controllers'

// Javascript imports
import './javascripts/hotel/turbolinks_prepare_for_cache'

import 'chartkick/chart.js'
import ApexCharts from 'apexcharts'
window.ApexCharts = ApexCharts

ActiveStorage.start()

document.addEventListener('turbo:load', (event) => { })
